import { inject, injectable } from 'tsyringe';
import { DeletableCompanyFetchResponse, ICompanyRepository } from '@/models/interface/Management/ICompanyRepository';

@injectable()
export class FetchDeletableCompanyListService {
    constructor(@inject('ICompanyRepository') private readonly repository: ICompanyRepository) {}

    async handle(): Promise<DeletableCompanyFetchResponse> {
        return this.repository.getDeletableCompanyIndex();
    }
}
