import { inject, provide, Ref, ref } from '@vue/composition-api';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideTenantDetailProgressCircular = () => {
    const isTenantDetailProgressing = ref(false);

    provide('isTenantDetailProgressing', isTenantDetailProgressing);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useTenantDetailProgressCircular = () => {
    const isTenantDetailProgressing = inject('isTenantDetailProgressing') as Ref<boolean>;

    const tenantDetailProgressStart = (): void => {
        isTenantDetailProgressing.value = true;
    };

    const tenantDetailProgressEnd = (): void => {
        isTenantDetailProgressing.value = false;
    };

    return {
        isTenantDetailProgressing,
        tenantDetailProgressStart,
        tenantDetailProgressEnd,
    };
};
