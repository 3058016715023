




























import { ManagementList } from '@/configs/ManagementList';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    setup() {
        return {
            ManagementList,
        };
    },
});
