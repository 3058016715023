import {
    IReflectScheduleRepository,
    ReflectScheduleListResponse,
} from '@/models/interface/Management/IReflectScheduleRepository';
import { ReflectedStatus } from '@/types/tenant';
import { inject, injectable } from 'tsyringe';

@injectable()
export class ScheduleFetchListService {
    constructor(@inject('IReflectScheduleRepository') private readonly repository: IReflectScheduleRepository) {}

    async handle(status: ReflectedStatus): Promise<ReflectScheduleListResponse> {
        return this.repository.fetchScheduleList(status);
    }
}
