
























































import { defineComponent } from '@vue/composition-api';
import ImplementTargetScheduleComponent from '@/components/Management/molecules/ImplementTargetSchedule.vue';
import ProgressCircularComponent from '@/components/Common/molecules/ProgressCircular.vue';
import CancelTargetScheduleComponent from '@/components/Management/molecules/CancelTargetSchedule.vue';
import { useScheduleConfirmDialog } from '@/composables/store/Management/Schedule/useScheduleConfirmDialog';
import { useReflectSchedule } from '@/composables/store/Management/Schedule/useReflectSchedule';
import { useScheduleProgressCircular } from '@/composables/store/Management/Schedule/useScheduleProgressCircular';

export default defineComponent({
    components: {
        ImplementTargetSchedule: ImplementTargetScheduleComponent,
        CancelTargetSchedule: CancelTargetScheduleComponent,
        ProgressCircular: ProgressCircularComponent,
    },
    setup() {
        const { dialogFlag, closeDialog, isImplementConfirmDialog, cancelTargetSchedule } = useScheduleConfirmDialog();
        const { fetchIndex, implement, cancel, error } = useReflectSchedule();
        const { isScheduleImplementProgressing } = useScheduleProgressCircular();

        const getLabel = (): string => {
            return isImplementConfirmDialog() ? '実行' : '取消';
        };

        const getProgressingText = (): string => {
            return isImplementConfirmDialog() ? '実行中' : '取消中';
        };

        const clickImplement = async (): Promise<void> => {
            await implement();
            if (error.value.hasError) return;

            fetchIndex();
            closeDialog();
        };

        const clickCancel = async (): Promise<void> => {
            if (cancelTargetSchedule.value === undefined) return;
            await cancel(cancelTargetSchedule.value);

            if (error.value.hasError) return;

            fetchIndex();
            closeDialog();
        };

        return {
            dialogFlag,
            isScheduleImplementProgressing,
            error,
            isImplementConfirmDialog,
            closeDialog,
            getLabel,
            getProgressingText,
            clickImplement,
            clickCancel,
        };
    },
});
