import { inject, provide, Ref, ref } from '@vue/composition-api';
import { ErrorStatus } from '@/types/common';
import { container } from 'tsyringe';
import { CompanyDeleteService } from '@/models/service/Management/CompanyDeleteService';
import { isFailedResponse } from '@/models/interface/common';
import { useCompanyDeleteProgressCircular } from '@/composables/store/Management/useCompanyDeleteProgressCircular';
import { CompanyInfo } from '@/models/interface/Management/ICompanyRepository';
import { useCompanyListProgressCircular } from '@/composables/store/Management/useCompanyListProgressCircular';
import { FetchDeletableCompanyListService } from '@/models/service/Management/FetchDeletableCompanyListService';

const DeletableCompanyFetchIndex = container.resolve(FetchDeletableCompanyListService);
const CompanyDelete = container.resolve(CompanyDeleteService);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideCompanyDelete = () => {
    const error = ref<ErrorStatus>({
        hasError: false,
        message: '',
    });

    const deletableCompanyList = ref<CompanyInfo[]>([]);

    const deleteList = ref<number[]>([]);

    provide('error', error);
    provide('deletableCompanyList', deletableCompanyList);
    provide('deleteList', deleteList);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useCompanyDelete = () => {
    const error = inject('error') as Ref<ErrorStatus>;
    const deletableCompanyList = inject('deletableCompanyList') as Ref<CompanyInfo[]>;
    const deleteList = inject('deleteList') as Ref<number[]>;

    const initStatus = (): void => {
        error.value.hasError = false;
        error.value.message = '';
    };

    const initDeleteList = (): void => {
        deleteList.value = [];
    };

    const { companyListProgressStart, companyListProgressEnd } = useCompanyListProgressCircular();

    const fetchList = async (): Promise<void> => {
        companyListProgressStart();

        const response = await DeletableCompanyFetchIndex.handle();

        if (isFailedResponse(response)) {
            error.value.hasError = true;
            error.value.message = response.data.data.message;
            companyListProgressEnd();
            return;
        }

        initStatus();
        deletableCompanyList.value = response.data.data;
        companyListProgressEnd();
    };

    const { companyDeleteProgressStart, companyDeleteProgressEnd } = useCompanyDeleteProgressCircular();

    const deleteCompany = async (): Promise<void> => {
        companyDeleteProgressStart();
        const response = await CompanyDelete.handle(deleteList.value);

        if (isFailedResponse(response)) {
            error.value.hasError = true;
            error.value.message = response.data.data.message;
            return;
        }

        initStatus();
        initDeleteList();
        companyDeleteProgressEnd();
    };

    return {
        error,
        deletableCompanyList,
        deleteCompany,
        deleteList,
        fetchList,
    };
};
