import { ReflectScheduleInfo } from '@/types/schedule';
import { inject, provide, Ref, ref } from '@vue/composition-api';
import { useReflectSchedule } from './useReflectSchedule';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideScheduleConfirmDialog = () => {
    const dialogFlag = ref(false);
    const cancelTargetSchedule = ref<ReflectScheduleInfo>();

    provide('dialogFlag', dialogFlag);
    provide('cancelTargetSchedule', cancelTargetSchedule);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useScheduleConfirmDialog = () => {
    const dialogFlag = inject('dialogFlag') as Ref<boolean>;
    const cancelTargetSchedule = inject('cancelTargetSchedule') as Ref<ReflectScheduleInfo | undefined>;

    const openImplementDialog = (): void => {
        dialogFlag.value = true;
        cancelTargetSchedule.value = undefined;
    };

    const openCancelDialog = (target: ReflectScheduleInfo): void => {
        dialogFlag.value = true;
        cancelTargetSchedule.value = target;
    };

    const { initStatus } = useReflectSchedule();

    const closeDialog = (): void => {
        initStatus();
        dialogFlag.value = false;
    };

    const isImplementConfirmDialog = (): boolean => {
        return cancelTargetSchedule.value === undefined;
    };

    return {
        dialogFlag,
        cancelTargetSchedule,
        openImplementDialog,
        openCancelDialog,
        closeDialog,
        isImplementConfirmDialog,
    };
};
