import { inject, provide, Ref, ref } from '@vue/composition-api';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideTenantDeleteProgressCircular = () => {
    const isTenantFetchProgressing = ref(false);
    const isTenantDeleteProgressing = ref(false);

    provide('isTenantFetchProgressing', isTenantFetchProgressing);
    provide('isTenantDeleteProgressing', isTenantDeleteProgressing);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useTenantDeleteProgressCircular = () => {
    const isTenantFetchProgressing = inject('isTenantFetchProgressing') as Ref<boolean>;
    const isTenantDeleteProgressing = inject('isTenantDeleteProgressing') as Ref<boolean>;

    const tenantFetchProgressStart = (): void => {
        isTenantFetchProgressing.value = true;
    };

    const tenantFetchProgressEnd = (): void => {
        isTenantFetchProgressing.value = false;
    };

    const tenantDeleteProgressStart = (): void => {
        isTenantDeleteProgressing.value = true;
    };

    const tenantDeleteProgressEnd = (): void => {
        isTenantDeleteProgressing.value = false;
    };

    return {
        isTenantFetchProgressing,
        isTenantDeleteProgressing,
        tenantFetchProgressStart,
        tenantFetchProgressEnd,
        tenantDeleteProgressStart,
        tenantDeleteProgressEnd,
    };
};
