import { inject, injectable } from 'tsyringe';
import { AnalysisPlanCreationResponse } from '@/models/interface/Management/IAnalysisPlanRepository';
import { IAnalysisPlanRepository } from '@/models/interface/Management/IAnalysisPlanRepository';

@injectable()
export class AnalysisPlanCreationService {
    constructor(@inject('IAnalysisPlanRepository') private readonly repository: IAnalysisPlanRepository) {}

    async handle(
        name: string,
        time: number,
        additionalFee: number,
        standardPlanFlag: boolean,
        memo?: string,
    ): Promise<AnalysisPlanCreationResponse> {
        return this.repository.postAnalysisPlanCreation(name, time, additionalFee, standardPlanFlag, memo);
    }
}
