import { inject, provide, Ref, ref } from '@vue/composition-api';
import { container } from 'tsyringe';
import { TenantCreationService } from '@/models/service/Management/TenantCreationService';
import { isFailedResponse } from '@/models/interface/common';
import { ErrorStatus } from '@/types/common';
import { StatsServerFetchListService } from '@/models/service/Management/StatsServerFetchListService';
import { StatsServerInfo } from '@/models/interface/Management/IStatsServerRepository';

const TenantCreation = container.resolve(TenantCreationService);
const StatsServerFetchIndex = container.resolve(StatsServerFetchListService);

type TenantCreationForm = {
    company: {
        id: number;
        name: string;
    };
    tenantName: string;
    tenantIdentifier: string;
    statsServer: StatsServerInfo;
};

type statsServerForm = {
    id: number;
    value: string;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideTenantCreationForm = () => {
    const error = ref<ErrorStatus>({
        hasError: false,
        message: '',
    });

    const statsServerList = ref<statsServerForm[]>([]);

    const tenantForm = ref<TenantCreationForm>({
        company: {
            id: 0,
            name: '',
        },
        tenantName: '',
        tenantIdentifier: '',
        statsServer: {
            id: 0,
            name: '',
            domain: '',
        },
    });

    provide('error', error);
    provide('statsServerList', statsServerList);
    provide('tenantForm', tenantForm);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useTenantCreationForm = () => {
    const error = inject('error') as Ref<ErrorStatus>;
    const statsServerList = inject('statsServerList') as Ref<statsServerForm[]>;
    const tenantForm = inject('tenantForm') as Ref<TenantCreationForm>;

    const initStatus = (): void => {
        error.value.hasError = false;
        error.value.message = '';
    };

    const initTenant = (): void => {
        tenantForm.value.tenantName = '';
        tenantForm.value.tenantIdentifier = '';
        tenantForm.value.statsServer = {
            id: 0,
            name: '',
            domain: '',
        };
    };

    const initCompany = (): void => {
        tenantForm.value.company = {
            id: 0,
            name: '',
        };
    };

    //TODO Pickで型定義
    const beforeSetCompany = (company: { id: number; name: string }): void => {
        tenantForm.value.company = company;
    };

    const fetchStatsServer = async (): Promise<void> => {
        const result = await StatsServerFetchIndex.handle();

        if (isFailedResponse(result)) {
            error.value.hasError = true;
            error.value.message = result.data.data.message;
            return;
        }
        initStatus();

        makeStatsServerList(result.data.data);
    };

    /**
     * statsサーバーリストの整形
     * @param list
     */
    const makeStatsServerList = (list: StatsServerInfo[]): void => {
        list.map(statsServer => {
            const value = statsServer.name ? statsServer.domain + '(' + statsServer.name + ')' : statsServer.domain;

            statsServerList.value.push({
                id: statsServer.id,
                value: value,
            });
        });
    };

    /**
     * テナントの作成
     */
    const postCreation = async (): Promise<boolean> => {
        initStatus();

        const result = await TenantCreation.handle(
            tenantForm.value.company.id,
            tenantForm.value.tenantName,
            tenantForm.value.tenantIdentifier,
            tenantForm.value.statsServer.id,
        );

        if (isFailedResponse(result)) {
            error.value.hasError = true;
            error.value.message = result.data.data.message;
            return false;
        }
        initStatus();

        return true;
    };

    return {
        error,
        statsServerList,
        tenantForm,
        fetchStatsServer,
        postCreation,
        initTenant,
        initCompany,
        beforeSetCompany,
    };
};
