import { isFailedResponse } from '@/models/interface/common';
import { ReflectSchedule } from '@/models/interface/Management/IReflectScheduleRepository';
import { ScheduleCancelService } from '@/models/service/Management/Schedule/ScheduleCancelService';
import { ScheduleFetchListService } from '@/models/service/Management/Schedule/ScheduleFetchLIstService';
import { ScheduleImplementService } from '@/models/service/Management/Schedule/ScheduleImplementService';
import { ErrorStatus } from '@/types/common';
import { ReflectScheduleInfo } from '@/types/schedule';
import { ReflectedStatusValues } from '@/types/tenant';
import { inject, provide, Ref, ref } from '@vue/composition-api';
import { container } from 'tsyringe';
import { useScheduleProgressCircular } from './useScheduleProgressCircular';

const ScheduleFetchIndex = container.resolve(ScheduleFetchListService);
const ScheduleImplement = container.resolve(ScheduleImplementService);
const ScheduleCancel = container.resolve(ScheduleCancelService);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideReflectSchedule = () => {
    const error = ref<ErrorStatus>({
        hasError: false,
        message: '',
    });

    const scheduleList = ref<ReflectScheduleInfo[]>([]);

    provide('error', error);
    provide('scheduleList', scheduleList);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useReflectSchedule = () => {
    const error = inject('error') as Ref<ErrorStatus>;
    const scheduleList = inject('scheduleList') as Ref<ReflectScheduleInfo[]>;

    const initStatus = (): void => {
        error.value.hasError = false;
        error.value.message = '';
    };

    const {
        scheduleListProgressStart,
        scheduleListProgressEnd,
        scheduleImplementProgressStart,
        scheduleImplementProgressEnd,
    } = useScheduleProgressCircular();

    const setScheduleList = (list: ReflectSchedule[]): void => {
        scheduleList.value = list.map((item, index) => {
            return {
                no: index + 1,
                scheduleId: item.scheduleId,
                type: item.type,
                registeredAt: new Date(item.registeredAt),
                contents: item.contents,
            };
        });
    };

    const fetchIndex = async (): Promise<void> => {
        scheduleListProgressStart();
        const response = await ScheduleFetchIndex.handle(ReflectedStatusValues[0]);

        if (isFailedResponse(response)) {
            error.value.hasError = true;
            error.value.message = response.data.data.message;
            scheduleListProgressEnd();
            return;
        }

        initStatus();
        setScheduleList(response.data.data);
        scheduleListProgressEnd();
    };

    const implement = async (): Promise<void> => {
        scheduleImplementProgressStart();

        const response = await ScheduleImplement.handle();

        if (isFailedResponse(response)) {
            error.value.hasError = true;
            error.value.message = response.data.data.message;
            scheduleImplementProgressEnd();
            return;
        }

        initStatus();
        scheduleImplementProgressEnd();
    };

    const cancel = async (target: ReflectScheduleInfo): Promise<void> => {
        scheduleImplementProgressStart();

        const response = await ScheduleCancel.handle(target.scheduleId, target.type);

        if (isFailedResponse(response)) {
            error.value.hasError = true;
            error.value.message = response.data.data.message;
            scheduleImplementProgressEnd();
            return;
        }

        initStatus();
        scheduleImplementProgressEnd();
    };

    return {
        error,
        scheduleList,
        fetchIndex,
        implement,
        cancel,
        initStatus,
    };
};
