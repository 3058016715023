import { ITenantMRepository, TenantCreationResponse } from '@/models/interface/Management/ITenantMRepository';
import { inject, injectable } from 'tsyringe';

@injectable()
export class TenantCreationService {
    constructor(@inject('ITenantMRepository') private readonly repository: ITenantMRepository) {}

    async handle(
        companyId: number,
        tenantName: string,
        tenantIdentifier: string,
        statsDomainAddressId: number,
    ): Promise<TenantCreationResponse> {
        return this.repository.postTenantCreation(companyId, tenantName, tenantIdentifier, statsDomainAddressId);
    }
}
