export const ManagementList = [
    {
        category: 'Application',
        list: [
            { title: 'スケジュール管理', link: '/management/reflect-schedules' },
            { title: '簡易ログイン', link: '/management/login' },
            { title: 'データ復旧', link: '/management/restore' },
        ],
    },
    {
        category: 'Contract',
        list: [
            { title: '会社一覧', link: '/management' },
            { title: '会社詳細', link: '/management/company-detail' },
            { title: '会社登録', link: '/management/registration' },
            { title: '会社削除', link: '/management/company-delete' },
            { title: 'テナント削除', link: '/management/tenant-delete' },
        ],
    },
    {
        category: 'Plan',
        list: [{ title: 'プラン管理', link: '/management/plan-management' }],
    },
    {
        category: 'Payment',
        list: [{ title: '利用明細', link: '/management/usage-bill' }],
    },
];
