import { ITenantMRepository, TenantDetailFetchResponse } from '@/models/interface/Management/ITenantMRepository';
import { inject, injectable } from 'tsyringe';

@injectable()
export class FetchTenantDetailService {
    constructor(@inject('ITenantMRepository') private readonly repository: ITenantMRepository) {}

    async handle(tenantId: number): Promise<TenantDetailFetchResponse> {
        return this.repository.getTenantDetail(tenantId);
    }
}
