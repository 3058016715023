import { inject, injectable } from 'tsyringe';
import { CompanyRegistrationResponse, ICompanyRepository } from '@/models/interface/Management/ICompanyRepository';

@injectable()
export class CompanyRegistrationService {
    constructor(@inject('ICompanyRepository') private readonly repository: ICompanyRepository) {}

    async handle(companyName: string, companyIdentifier: string): Promise<CompanyRegistrationResponse> {
        const formData = new FormData();

        formData.append('name', companyName);
        formData.append('identifier', companyIdentifier);

        return this.repository.postCompanyRegistration(formData);
    }
}
