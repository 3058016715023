























import { useReflectSchedule } from '@/composables/store/Management/Schedule/useReflectSchedule';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    setup() {
        const { scheduleList } = useReflectSchedule();

        return {
            scheduleList,
        };
    },
});
