import { inject, provide, Ref, ref } from '@vue/composition-api';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideCompanyListProgressCircular = () => {
    const isCompanyListProgressing = ref(false);
    const isNotCreatedCompanyListProgressing = ref(false);

    provide('isCompanyListProgressing', isCompanyListProgressing);
    provide('isNotCreatedCompanyListProgressing', isNotCreatedCompanyListProgressing);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useCompanyListProgressCircular = () => {
    const isCompanyListProgressing = inject('isCompanyListProgressing') as Ref<boolean>;
    const isNotCreatedCompanyListProgressing = inject('isNotCreatedCompanyListProgressing') as Ref<boolean>;

    const companyListProgressStart = (): void => {
        isCompanyListProgressing.value = true;
    };

    const companyListProgressEnd = (): void => {
        isCompanyListProgressing.value = false;
    };

    const notCreatedTenantCompanyListProgressStart = (): void => {
        isNotCreatedCompanyListProgressing.value = true;
    };

    const notCreatedTenantCompanyListProgressEnd = (): void => {
        isNotCreatedCompanyListProgressing.value = false;
    };

    return {
        isCompanyListProgressing,
        isNotCreatedCompanyListProgressing,
        companyListProgressStart,
        companyListProgressEnd,
        notCreatedTenantCompanyListProgressStart,
        notCreatedTenantCompanyListProgressEnd,
    };
};
