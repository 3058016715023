



































































import { defineComponent } from '@vue/composition-api';
import ProgressCircularComponent from '@/components/Common/molecules/ProgressCircular.vue';
import PlanReflectDatePickerComponent from '@/components/Management/molecules/PlanReflectDatePicker.vue';
import ContractMemoAreaComponent from '@/components/Management/molecules/ContractMemoArea.vue';
import { useCompanyPlan } from '@/composables/store/Management/AnalysisPlan/useCompanyPlan';
import { useCompanyContractConfirmDialog } from '@/composables/store/Management/AnalysisPlan/useCompanyContractConfirmDialog';
import { useTime } from '@/composables/function/Common/useTime';
import { useRouter } from '@/composables/function/Common/useRouter';

export default defineComponent({
    components: {
        ProgressCircular: ProgressCircularComponent,
        PlanReflectDatePicker: PlanReflectDatePickerComponent,
        ContractMemoArea: ContractMemoAreaComponent,
    },
    setup() {
        const {
            postContract,
            company,
            error,
            selectedPlan,
            initSelectedPlan,
            initTargetCompany,
            initContractFormInfo,
            contractFormInfo,
            isContractProgressing,
        } = useCompanyPlan();
        const { contractDialogFlag, closeDialog } = useCompanyContractConfirmDialog();
        const { convertToHourFormatNumber } = useTime();
        const router = useRouter();

        const clickImplement = async (): Promise<void> => {
            await postContract();
            if (error.value.hasError) return;

            closeDialog();
            router.push({
                name: 'CompanyDetail',
                params: {
                    id: `${company.value.id}`,
                    name: `${company.value.name}`,
                },
            });

            initTargetCompany();
            initSelectedPlan();
            initContractFormInfo();
        };

        const loadingClass = (): string => (isContractProgressing.value ? 'deleting' : '');

        return {
            error,
            contractFormInfo,
            contractDialogFlag,
            convertToHourFormatNumber,
            closeDialog,
            clickImplement,
            selectedPlan,
            isContractProgressing,
            loadingClass,
        };
    },
});
