





















import { defineComponent } from '@vue/composition-api';
import { useScheduleConfirmDialog } from '@/composables/store/Management/Schedule/useScheduleConfirmDialog';
import { useScheduleTypeLabel } from '@/composables/function/Management/useScheduleTypeLabel';

export default defineComponent({
    setup() {
        const { cancelTargetSchedule } = useScheduleConfirmDialog();
        const { getLabel } = useScheduleTypeLabel();

        return {
            cancelTargetSchedule,
            getLabel,
        };
    },
});
