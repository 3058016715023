import { ITenantMRepository, TenantFetchResponse } from '@/models/interface/Management/ITenantMRepository';
import { inject, injectable } from 'tsyringe';

@injectable()
export class TenantDeleteService {
    constructor(@inject('ITenantMRepository') private readonly repository: ITenantMRepository) {}

    async handle(tenantIds: number[]): Promise<TenantFetchResponse> {
        return this.repository.deleteTenant(tenantIds);
    }
}
