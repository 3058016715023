import { ITenantMRepository, TenantFetchResponse } from '@/models/interface/Management/ITenantMRepository';
import { inject, injectable } from 'tsyringe';

@injectable()
export class TenantFetchListWithSystemService {
    constructor(@inject('ITenantMRepository') private readonly repository: ITenantMRepository) {}

    async handle(companyId: number): Promise<TenantFetchResponse> {
        return this.repository.getTenantIndexWithSystem(companyId);
    }
}
