


















import { defineComponent } from '@vue/composition-api';
import ManagementListComponent from '@/components/Management/ecosystems/ManagementList.vue';
import ReflectScheduleConfirmDialog from '@/components/Management/organisms/ReflectScheduleConfirmDialog.vue';
import CompanyContractConfirmDialog from '@/components/Management/organisms/CompanyContractConfirmDialog.vue';
import CompanyDetailConfirmDialog from '@/components/Management/organisms/CompanyDetailConfirmDialog.vue';
import AutoStopConfirmDialog from '@/components/Management/organisms/AutoStopConfirmDialog.vue';
import AnalysisPlanDeleteDialog from '@/components/Management/organisms/AnalysisPlanDeleteDialog.vue';
import { provideCompanyRegistrationForm } from '@/composables/store/Management/useCompanyRegistration';
import { provideTenantCreationForm } from '@/composables/store/Management/useTenantCreationForm';
import { provideCompanyList } from '@/composables/store/Management/useCompanyList';
import { provideCompanyListProgressCircular } from '@/composables/store/Management/useCompanyListProgressCircular';
import { provideReflectSchedule } from '@/composables/store/Management/Schedule/useReflectSchedule';
import { provideScheduleProgressCircular } from '@/composables/store/Management/Schedule/useScheduleProgressCircular';
import { provideCompanyDeleteProgressCircular } from '@/composables/store/Management/useCompanyDeleteProgressCircular';
import { provideCompanyDelete } from '@/composables/store/Management/useCompanyDelete';
import { provideTenantDeleteProgressCircular } from '@/composables/store/Management/useTenantDeleteProgressCircular';
import { provideTenantDelete } from '@/composables/store/Management/useTenantDelete';
import {
    provideScheduleConfirmDialog,
    useScheduleConfirmDialog,
} from '@/composables/store/Management/Schedule/useScheduleConfirmDialog';
import { provideTenantDetail } from '@/composables/store/Management/useTenantDetail';
import { provideTenantDetailProgressCircular } from '@/composables/store/Management/useTenantDetailProgressCircular';
import { provideAnalysisPlanCreationForm } from '@/composables/store/Management/AnalysisPlan/useAnalysisPlanCreationForm';
import { provideCompanyPlan } from '@/composables/store/Management/AnalysisPlan/useCompanyPlan';
import {
    provideCompanyContractConfirmDialog,
    useCompanyContractConfirmDialog,
} from '@/composables/store/Management/AnalysisPlan/useCompanyContractConfirmDialog';
import { provideCompanyMenu } from '@/composables/store/Management/useCompanyMenu';
import { provideCompanyDetail } from '@/composables/store/Management/useCompanyDetail';
import {
    provideCompanyDetailConfirmDialog,
    useCompanyDetailConfirmDialog,
} from '@/composables/store/Management/useCompanyDetailConfirmDialog';
import {
    provideAutoStopConfirmDialog,
    useAutoStopConfirmDialog,
} from '@/composables/store/Management/useAutoStopConfirmDialog';
import {
    provideAnalysisPlanDeleteDialog,
    useAnalysisPlanDeleteDialog,
} from '@/composables/store/Management/AnalysisPlan/useAnalysisPlanDeleteDialog';
import { provideCompanyPlanSearchBox } from '@/composables/store/Management/AnalysisPlan/useCompanyPlanSearchBox';
import { provideUsageBill } from '@/composables/store/Management/useUsageBill';
import { provideAnalysisPlanManagement } from '@/composables/store/Management/AnalysisPlan/useAnalysisPlanManagement';
import { provideSimpleLogin } from '@/composables/store/Management/useSimpleLogin';

export default defineComponent({
    components: {
        ManagementList: ManagementListComponent,
        ScheduleConfirmDialog: ReflectScheduleConfirmDialog,
        ContractConfirmDialog: CompanyContractConfirmDialog,
        CompanyDetailConfirmDialog: CompanyDetailConfirmDialog,
        AutoStopConfirmDialog: AutoStopConfirmDialog,
        AnalysisPlanDeleteDialog: AnalysisPlanDeleteDialog,
    },
    setup() {
        // 依存性なし
        provideCompanyRegistrationForm();
        provideTenantCreationForm();
        provideAnalysisPlanCreationForm();
        provideCompanyList();
        provideCompanyListProgressCircular();
        provideScheduleProgressCircular();
        provideTenantDeleteProgressCircular();
        provideCompanyDeleteProgressCircular();
        provideTenantDetailProgressCircular();
        provideCompanyPlan();
        provideCompanyDetail();
        provideCompanyDetailConfirmDialog();
        provideAnalysisPlanDeleteDialog();
        provideCompanyPlanSearchBox();
        provideAutoStopConfirmDialog();
        provideSimpleLogin();

        // 依存性あり
        provideTenantDeleteProgressCircular();
        provideTenantDelete();
        provideReflectSchedule();
        provideScheduleConfirmDialog();
        provideCompanyContractConfirmDialog();
        provideCompanyDelete();
        provideTenantDetail();
        provideCompanyMenu();
        provideAnalysisPlanManagement();
        provideUsageBill();

        const { closeDialog: scheduleCloseDialog } = useScheduleConfirmDialog();
        const { closeDialog: contractCloseDialog } = useCompanyContractConfirmDialog();
        const { closeDialog: detailCloseDialog } = useCompanyDetailConfirmDialog();
        const { closeDialog: autoStopCloseDialog } = useAutoStopConfirmDialog();
        const { closeDialog: analysisPlanDeleteCloseDialog } = useAnalysisPlanDeleteDialog();
        addEventListener('popstate', () => {
            scheduleCloseDialog();
            contractCloseDialog();
            detailCloseDialog();
            autoStopCloseDialog();
            analysisPlanDeleteCloseDialog();
        });
    },
});
