

































import { useAutoStopConfirmDialog } from '@/composables/store/Management/useAutoStopConfirmDialog';
import { useCompanyDetail } from '@/composables/store/Management/useCompanyDetail';
import { defineComponent } from '@vue/composition-api';
import ProgressCircularComponent from '@/components/Common/molecules/ProgressCircular.vue';

export default defineComponent({
    components: {
        ProgressCircular: ProgressCircularComponent,
    },
    setup() {
        const { autoStopConfirmDialog, closeDialog } = useAutoStopConfirmDialog();
        const { companyDetail, fetchCompanyDetail, changeAutoStopSetting, isAutoStopSettingProgressing, error } =
            useCompanyDetail();

        const requestAutoStopSetting = async (): Promise<void> => {
            await changeAutoStopSetting();
            closeDialog();
            if (!error.value.hasError) {
                fetchCompanyDetail(companyDetail.value.id);
            }
        };

        return {
            autoStopConfirmDialog,
            closeDialog,
            requestAutoStopSetting,
            isAutoStopSettingProgressing,
            error,
        };
    },
});
