import { inject, provide, Ref, ref } from '@vue/composition-api';
import { container } from 'tsyringe';
import { FetchCompanyTenantListService } from '@/models/service/Company/FetchCompanyTenantListService';
import { isFailedResponse } from '@/models/interface/common';
import { ErrorStatus } from '@/types/common';
import { CompanyTenantInfo } from '@/models/interface/Management/ICompanyRepository';
import { useCompanyListProgressCircular } from '@/composables/store/Management/useCompanyListProgressCircular';
import { useClipBoard } from '@/composables/function/Common/useClipBoard';
import { useSleep } from '@/composables/function/Common/useSleep';
import { AnalysisPlanInfo } from '@/models/interface/Management/IAnalysisPlanRepository';

const CompanyTenantFetchIndex = container.resolve(FetchCompanyTenantListService);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideCompanyList = () => {
    const error = ref<ErrorStatus>({
        hasError: false,
        message: '',
    });

    const companyList = ref<CompanyTenantInfo[]>([]);
    const dontHasTenantCompanyList = ref<CompanyTenantInfo[]>([]);
    const searchName = ref<string>('');
    const searchPlan = ref<AnalysisPlanInfo>();
    const copyFlag = ref<boolean>(false);

    provide('error', error);
    provide('companyList', companyList);
    provide('dontHasTenantCompanyList', dontHasTenantCompanyList);
    provide('searchName', searchName);
    provide('searchPlan', searchPlan);
    provide('copyFlag', copyFlag);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useCompanyList = () => {
    const error = inject('error') as Ref<ErrorStatus>;
    const companyList = inject('companyList') as Ref<CompanyTenantInfo[]>;
    const dontHasTenantCompanyList = inject('dontHasTenantCompanyList') as Ref<CompanyTenantInfo[]>;
    const searchName = inject('searchName') as Ref<string | undefined>;
    const searchPlan = inject('searchPlan') as Ref<AnalysisPlanInfo | undefined>;
    const copyFlag = inject('copyFlag') as Ref<boolean>;

    const initStatus = (): void => {
        error.value.hasError = false;
        error.value.message = '';
    };

    const initSearchParam = (): void => {
        searchName.value = undefined;
        searchPlan.value = undefined;
    };

    const setCompanyList = (data: CompanyTenantInfo[]): void => {
        companyList.value = data.filter(companyTenant => companyTenant.tenantCount !== 0);
    };

    const setDontHasTenantCompany = (data: CompanyTenantInfo[]): void => {
        if (isSearch()) return;
        dontHasTenantCompanyList.value = data.filter(companyTenant => companyTenant.tenantCount === 0);
    };

    const isSearch = (): boolean => {
        return !!searchName.value;
    };

    const { clipboardCopy } = useClipBoard();
    const { sleep } = useSleep();
    const copy = async (apiKey: string): Promise<void> => {
        clipboardCopy(apiKey);
        copyFlag.value = true;
        await sleep(3000);
        copyFlag.value = false;
    };

    const {
        companyListProgressStart,
        companyListProgressEnd,
        notCreatedTenantCompanyListProgressStart,
        notCreatedTenantCompanyListProgressEnd,
    } = useCompanyListProgressCircular();
    const fetchList = async (): Promise<void> => {
        companyListProgressStart();
        if (!isSearch()) notCreatedTenantCompanyListProgressStart();
        const response = await CompanyTenantFetchIndex.handle(searchName.value, searchPlan.value);

        if (isFailedResponse(response)) {
            error.value.hasError = true;
            error.value.message = response.data.data.message;
            companyListProgressEnd();
            notCreatedTenantCompanyListProgressEnd();
            return;
        }

        initStatus();
        setCompanyList(response.data.data);
        setDontHasTenantCompany(response.data.data);
        companyListProgressEnd();
        notCreatedTenantCompanyListProgressEnd();
    };

    return {
        error,
        searchName,
        searchPlan,
        copyFlag,
        companyList,
        dontHasTenantCompanyList,
        copy,
        fetchList,
        setDontHasTenantCompany,
        initSearchParam,
    };
};
