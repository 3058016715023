import { isFailedResponse, CreateSuccessResponse } from '@/models/interface/common';
import { AnalysisPlanCreationService } from '@/models/service/Management/AnalysisPlanCreationService';
import { ErrorStatus } from '@/types/common';
import { inject, provide, Ref, ref } from '@vue/composition-api';
import { container } from 'tsyringe';
import { useTime } from '@/composables/function/Common/useTime';
import { useCircular } from '@/composables/function/Common/useCircular';

const AnalysisPlanCreation = container.resolve(AnalysisPlanCreationService);

export type AnalysisPlanCreationForm = {
    name: string;
    time: string;
    additionalFee: string;
    memo?: string;
};

export const init: AnalysisPlanCreationForm = { name: '', time: '', additionalFee: '' };

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideAnalysisPlanCreationForm = () => {
    const error = ref<ErrorStatus>({
        hasError: false,
        message: '',
    });

    const analysisPlan = ref<AnalysisPlanCreationForm>(init);
    const isPlanCreateProgressing = ref(false);
    const standardPlanFlag = ref(false);

    provide('error', error);
    provide('analysisPlan', analysisPlan);
    provide('isPlanCreateProgressing', isPlanCreateProgressing);
    provide('standardPlanFlag', standardPlanFlag);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useAnalysisPlanCreationForm = () => {
    const error = inject('error') as Ref<ErrorStatus>;
    const analysisPlan = inject('analysisPlan') as Ref<AnalysisPlanCreationForm>;
    const isPlanCreateProgressing = inject('isPlanCreateProgressing') as Ref<boolean>;
    const standardPlanFlag = inject('standardPlanFlag') as Ref<boolean>;

    const initStatus = (): void => {
        error.value.hasError = false;
        error.value.message = '';
    };

    const initPlan = (): void => {
        //TODO: initとrefで初期化できない..
        analysisPlan.value.additionalFee = '';
        analysisPlan.value.name = '';
        analysisPlan.value.time = '';
        analysisPlan.value.memo = undefined;
        standardPlanFlag.value = false;
    };

    const { convertToSecondFormatNumber } = useTime();
    const { progressStart, progressEnd } = useCircular();
    const addPlan = async (): Promise<boolean | CreateSuccessResponse> => {
        progressStart(isPlanCreateProgressing);
        initStatus();

        const result = await AnalysisPlanCreation.handle(
            analysisPlan.value.name,
            convertToSecondFormatNumber(analysisPlan.value.time),
            Number(analysisPlan.value.additionalFee),
            standardPlanFlag.value,
            analysisPlan.value.memo,
        );

        if (isFailedResponse(result)) {
            error.value.hasError = true;
            error.value.message = result.data.data.message;
            progressEnd(isPlanCreateProgressing);
            return false;
        }

        initStatus();
        progressEnd(isPlanCreateProgressing);
        return result;
    };

    return {
        error,
        analysisPlan,
        isPlanCreateProgressing,
        standardPlanFlag,
        initPlan,
        addPlan,
    };
};
