import { ScheduleType } from '@/types/schedule';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useScheduleTypeLabel = () => {
    const getLabel = (type: ScheduleType): string => {
        if (type === 'company') return '会社';
        return 'テナント';
    };

    return {
        getLabel,
    };
};
