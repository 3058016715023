






























import { defineComponent, ref } from '@vue/composition-api';
import { useCompanyPlan } from '@/composables/store/Management/AnalysisPlan/useCompanyPlan';

export default defineComponent({
    setup() {
        const { contractFormInfo, initContractFormInfo } = useCompanyPlan();
        const menu = ref(false);
        const dayFormat = (date: string): number => new Date(date).getDate();

        initContractFormInfo();

        return {
            contractFormInfo,
            menu,
            dayFormat,
        };
    },
});
