import { inject, provide, Ref, ref } from '@vue/composition-api';
import { container } from 'tsyringe';
import { isFailedResponse } from '@/models/interface/common';
import { ErrorStatus } from '@/types/common';
import { AnalysisPlanFetchListService } from '@/models/service/Management/AnalysisPlanFetchListService';
import { AnalysisPlanInfo } from '@/models/interface/Management/IAnalysisPlanRepository';

const AnalysisPlanFetchIndex = container.resolve(AnalysisPlanFetchListService);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideCompanyPlanSearchBox = () => {
    const error = ref<ErrorStatus>({
        hasError: false,
        message: '',
    });

    const menuPlanList = ref<AnalysisPlanInfo[]>([]);

    provide('error', error);
    provide('menuPlanList', menuPlanList);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useCompanyPlanSearchBox = () => {
    const error = inject('error') as Ref<ErrorStatus>;
    const menuPlanList = inject('menuPlanList') as Ref<AnalysisPlanInfo[]>;

    const initStatus = (): void => {
        error.value.hasError = false;
        error.value.message = '';
    };

    const setPlanList = (data: AnalysisPlanInfo[]): void => {
        menuPlanList.value = data;
    };

    const fetchMenuPlanList = async (): Promise<void> => {
        const response = await AnalysisPlanFetchIndex.handle();

        if (isFailedResponse(response)) {
            error.value.hasError = true;
            error.value.message = response.data.data.message;
            return;
        }

        initStatus();
        setPlanList(response.data.data);
    };

    return {
        error,
        menuPlanList,
        initStatus,
        fetchMenuPlanList,
    };
};
