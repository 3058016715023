
















import { defineComponent } from '@vue/composition-api';
import { useCompanyPlan } from '@/composables/store/Management/AnalysisPlan/useCompanyPlan';
import { useCompanyContractConfirmDialog } from '@/composables/store/Management/AnalysisPlan/useCompanyContractConfirmDialog';

export default defineComponent({
    setup() {
        const { contractFormInfo } = useCompanyPlan();
        const { memoFlag } = useCompanyContractConfirmDialog();

        return {
            contractFormInfo,
            memoFlag,
        };
    },
});
