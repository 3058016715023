import { inject, provide, Ref, ref } from '@vue/composition-api';
import { ErrorStatus } from '@/types/common';
import { isFailedResponse } from '@/models/interface/common';
import { container } from 'tsyringe';
import { CompanyRegistrationService } from '@/models/service/Management/CompanyRegistrationService';
import { useTenantCreationForm } from '@/composables/store/Management/useTenantCreationForm';

const CompanyRegistration = container.resolve(CompanyRegistrationService);

export type CompanyRegistrationForm = {
    company: {
        id: number;
        name: string;
        identifier: string;
    };
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideCompanyRegistrationForm = () => {
    const error = ref<ErrorStatus>({
        hasError: false,
        message: '',
    });

    const companyForm = ref<CompanyRegistrationForm>({
        company: {
            id: 0,
            name: '',
            identifier: '',
        },
    });

    provide('error', error);
    provide('companyForm', companyForm);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useCompanyRegistrationForm = () => {
    const error = inject('error') as Ref<ErrorStatus>;
    const companyForm = inject('companyForm') as Ref<CompanyRegistrationForm>;

    const initStatus = (): void => {
        error.value.hasError = false;
        error.value.message = '';
    };

    const initForm = (): void => {
        companyForm.value = {
            company: {
                id: 0,
                name: '',
                identifier: '',
            },
        };
    };

    const { beforeSetCompany } = useTenantCreationForm();

    /**
     * 会社登録
     */
    const postRegistration = async (): Promise<boolean> => {
        initStatus();
        const result = await CompanyRegistration.handle(
            companyForm.value.company.name,
            companyForm.value.company.identifier,
        );

        if (isFailedResponse(result)) {
            error.value.hasError = true;
            error.value.message = result.data.data.message;
            return false;
        }

        initStatus();

        companyForm.value.company.id = result.data.data.id;
        beforeSetCompany(companyForm.value.company);

        return true;
    };

    return {
        companyForm,
        error,
        initForm,
        postRegistration,
    };
};
