import { ref, provide, inject, Ref } from '@vue/composition-api';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideCompanyDeleteProgressCircular = () => {
    const isCompanyFetchProgressing = ref(false);
    const isCompanyDeleteProgressing = ref(false);

    provide('isCompanyFetchProgressing', isCompanyFetchProgressing);
    provide('isCompanyDeleteProgressing', isCompanyDeleteProgressing);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useCompanyDeleteProgressCircular = () => {
    const isCompanyFetchProgressing = inject('isCompanyFetchProgressing') as Ref<boolean>;
    const isCompanyDeleteProgressing = inject('isCompanyDeleteProgressing') as Ref<boolean>;

    const companyFetchProgressStart = (): void => {
        isCompanyFetchProgressing.value = true;
    };

    const companyFetchProgressEnd = (): void => {
        isCompanyFetchProgressing.value = false;
    };

    const companyDeleteProgressStart = (): void => {
        isCompanyDeleteProgressing.value = true;
    };

    const companyDeleteProgressEnd = (): void => {
        isCompanyDeleteProgressing.value = false;
    };

    return {
        isCompanyFetchProgressing,
        isCompanyDeleteProgressing,
        companyFetchProgressStart,
        companyFetchProgressEnd,
        companyDeleteProgressStart,
        companyDeleteProgressEnd,
    };
};
