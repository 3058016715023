import { inject, injectable } from 'tsyringe';
import { IStatsServerRepository, StatsServerFetchResponse } from '@/models/interface/Management/IStatsServerRepository';

@injectable()
export class StatsServerFetchListService {
    constructor(@inject('IStatsServerRepository') private readonly repository: IStatsServerRepository) {}

    async handle(): Promise<StatsServerFetchResponse> {
        return this.repository.getStatsServerIndex();
    }
}
