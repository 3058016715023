import { inject, provide, Ref, ref } from '@vue/composition-api';
import { ErrorStatus } from '@/types/common';
import { TenantDetailInfo } from '@/models/interface/Management/ITenantMRepository';
import { container } from 'tsyringe';
import { FetchTenantDetailService } from '@/models/service/Management/FetchTenantDetailService';
import { isFailedResponse } from '@/models/interface/common';
import { useTenantDetailProgressCircular } from '@/composables/store/Management/useTenantDetailProgressCircular';
import { useSleep } from '@/composables/function/Common/useSleep';
import { useClipBoard } from '@/composables/function/Common/useClipBoard';

const fetchTenantDetail = container.resolve(FetchTenantDetailService);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideTenantDetail = () => {
    const error = ref<ErrorStatus>({
        hasError: false,
        message: '',
    });

    const tenantDetail = ref<TenantDetailInfo>();
    const copyFlag = ref<boolean>(false);

    provide('error', error);
    provide('tenantDetail', tenantDetail);
    provide('copyFlag', copyFlag);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useTenantDetail = () => {
    const error = inject('error') as Ref<ErrorStatus>;
    const tenantDetail = inject('tenantDetail') as Ref<TenantDetailInfo>;
    const copyFlag = inject('copyFlag') as Ref<boolean>;

    const initStatus = (): void => {
        error.value.hasError = false;
        error.value.message = '';
    };

    const { clipboardCopy } = useClipBoard();
    const { sleep } = useSleep();
    const copy = async (apiKey: string): Promise<void> => {
        clipboardCopy(apiKey);
        copyFlag.value = true;
        await sleep(3000);
        copyFlag.value = false;
    };

    const { tenantDetailProgressStart, tenantDetailProgressEnd } = useTenantDetailProgressCircular();

    const fetchDetail = async (tenantId: number): Promise<void> => {
        tenantDetail.value = {} as TenantDetailInfo;

        tenantDetailProgressStart();
        const response = await fetchTenantDetail.handle(tenantId);

        if (isFailedResponse(response)) {
            error.value.hasError = true;
            error.value.message = response.data.data.message;
            tenantDetailProgressEnd();
            return;
        }

        initStatus();
        tenantDetail.value = response.data.data;
        tenantDetailProgressEnd();
    };

    return {
        error,
        tenantDetail,
        copyFlag,
        fetchDetail,
        copy,
    };
};
