import { ScheduleResponse, IReflectScheduleRepository } from '@/models/interface/Management/IReflectScheduleRepository';
import { inject, injectable } from 'tsyringe';

@injectable()
export class ScheduleImplementService {
    constructor(@inject('IReflectScheduleRepository') private readonly repository: IReflectScheduleRepository) {}

    async handle(): Promise<ScheduleResponse> {
        return this.repository.implementSchedule();
    }
}
