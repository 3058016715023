



























































import { defineComponent } from '@vue/composition-api';
import ProgressCircularComponent from '@/components/Common/molecules/ProgressCircular.vue';
import { useCompanyDetail } from '@/composables/store/Management/useCompanyDetail';
import { useCompanyMenu } from '@/composables/store/Management/useCompanyMenu';
import { useCompanyDetailConfirmDialog } from '@/composables/store/Management/useCompanyDetailConfirmDialog';

export default defineComponent({
    components: {
        ProgressCircular: ProgressCircularComponent,
    },
    setup() {
        const { error, isContractCancelProgressing, cancelContract, fetchCompanyDetail } = useCompanyDetail();
        const { detailConfirmDialog, cancelTargetContract, closeDialog } = useCompanyDetailConfirmDialog();
        const { selectedCompany } = useCompanyMenu();

        const clickImplement = async (): Promise<void> => {
            await cancelContract(cancelTargetContract.value.id);
            if (error.value.hasError) return;

            closeDialog();
            fetchCompanyDetail(selectedCompany.value);
        };

        const loadingClass = (): string => (isContractCancelProgressing.value ? 'deleting' : '');

        return {
            error,
            detailConfirmDialog,
            isContractCancelProgressing,
            cancelTargetContract,
            closeDialog,
            clickImplement,
            loadingClass,
        };
    },
});
