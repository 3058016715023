


















































import { defineComponent } from '@vue/composition-api';
import ProgressCircularComponent from '@/components/Common/molecules/ProgressCircular.vue';
import { useCompanyPlan } from '@/composables/store/Management/AnalysisPlan/useCompanyPlan';
import { useAnalysisPlanManagement } from '@/composables/store/Management/AnalysisPlan/useAnalysisPlanManagement';
import { useAnalysisPlanDeleteDialog } from '@/composables/store/Management/AnalysisPlan/useAnalysisPlanDeleteDialog';
import { useTime } from '@/composables/function/Common/useTime';

export default defineComponent({
    components: {
        ProgressCircular: ProgressCircularComponent,
    },
    setup() {
        const { fetchPlanList } = useCompanyPlan();

        const { error, targetPlan, isDeletingPlanProgressing, initTargetPlan, deletePlan } =
            useAnalysisPlanManagement();
        const { analysisPlanDeleteDialog, closeDialog } = useAnalysisPlanDeleteDialog();
        const { convertToHourFormatNumber } = useTime();

        const clickImplement = async (): Promise<void> => {
            await deletePlan();
            if (error.value.hasError) return;

            closeDialog();

            initTargetPlan();
            fetchPlanList();
        };

        const loadingClass = (): string => (isDeletingPlanProgressing.value ? 'deleting' : '');

        return {
            error,
            targetPlan,
            analysisPlanDeleteDialog,
            convertToHourFormatNumber,
            closeDialog,
            clickImplement,
            isDeletingPlanProgressing,
            loadingClass,
        };
    },
});
