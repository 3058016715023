import { inject, provide, Ref, ref } from '@vue/composition-api';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideScheduleProgressCircular = () => {
    const isScheduleListProgressing = ref(false);
    const isScheduleImplementProgressing = ref(false);

    provide('isScheduleListProgressing', isScheduleListProgressing);
    provide('isScheduleImplementProgressing', isScheduleImplementProgressing);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useScheduleProgressCircular = () => {
    const isScheduleListProgressing = inject('isScheduleListProgressing') as Ref<boolean>;
    const isScheduleImplementProgressing = inject('isScheduleImplementProgressing') as Ref<boolean>;

    const scheduleListProgressStart = (): void => {
        isScheduleListProgressing.value = true;
    };

    const scheduleListProgressEnd = (): void => {
        isScheduleListProgressing.value = false;
    };

    const scheduleImplementProgressStart = (): void => {
        isScheduleImplementProgressing.value = true;
    };

    const scheduleImplementProgressEnd = (): void => {
        isScheduleImplementProgressing.value = false;
    };

    return {
        isScheduleListProgressing,
        isScheduleImplementProgressing,
        scheduleListProgressStart,
        scheduleListProgressEnd,
        scheduleImplementProgressStart,
        scheduleImplementProgressEnd,
    };
};
