import { ScheduleResponse, IReflectScheduleRepository } from '@/models/interface/Management/IReflectScheduleRepository';
import { ScheduleType } from '@/types/schedule';
import { inject, injectable } from 'tsyringe';

@injectable()
export class ScheduleCancelService {
    constructor(@inject('IReflectScheduleRepository') private readonly repository: IReflectScheduleRepository) {}

    async handle(id: number, type: ScheduleType): Promise<ScheduleResponse> {
        return this.repository.cancelSchedule(id, type);
    }
}
