import { inject, provide, Ref, ref } from '@vue/composition-api';
import { container } from 'tsyringe';
import { TenantFetchListWithSystemService } from '@/models/service/Management/TenantFetchListWithSystemService';
import { TenantDeleteService } from '@/models/service/Management/TenantDeleteService';
import { isFailedResponse } from '@/models/interface/common';
import { ErrorStatus } from '@/types/common';
import { TenantSystemInfo } from '@/models/interface/Management/ITenantMRepository';
import { CompanyBasicInfo as RepositoryCompanyType } from '@/models/interface/Management/ICompanyRepository';
import { useTenantDeleteProgressCircular } from '@/composables/store/Management/useTenantDeleteProgressCircular';

const TenantWithSystemFetchIndex = container.resolve(TenantFetchListWithSystemService);
const TenantDelete = container.resolve(TenantDeleteService);

export type CompanyBasicInfo = RepositoryCompanyType;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideTenantDelete = () => {
    const error = ref<ErrorStatus>({
        hasError: false,
        message: '',
    });

    const tenantSystemList = ref<TenantSystemInfo[]>([]);
    const deleteSelected = ref<number[]>([]);

    provide('error', error);
    provide('tenantSystemList', tenantSystemList);
    provide('deleteSelected', deleteSelected);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useTenantDelete = () => {
    const error = inject('error') as Ref<ErrorStatus>;
    const tenantSystemList = inject('tenantSystemList') as Ref<TenantSystemInfo[]>;
    const deleteSelected = inject('deleteSelected') as Ref<number[]>;

    const initStatus = (): void => {
        error.value.hasError = false;
        error.value.message = '';
    };

    const initDeleteSelected = (): void => {
        deleteSelected.value = [];
    };

    const initTenantSystemList = (): void => {
        tenantSystemList.value = [];
    };

    const setTenantSystemList = (data: TenantSystemInfo[]): void => {
        tenantSystemList.value = data;
    };

    const { tenantFetchProgressStart, tenantFetchProgressEnd, tenantDeleteProgressStart, tenantDeleteProgressEnd } =
        useTenantDeleteProgressCircular();
    const fetchTenantList = async (companyId: number): Promise<void> => {
        tenantFetchProgressStart();
        const response = await TenantWithSystemFetchIndex.handle(companyId);

        if (isFailedResponse(response)) {
            error.value.hasError = true;
            error.value.message = response.data.data.message;
            tenantFetchProgressEnd();
            return;
        }

        initStatus();
        setTenantSystemList(response.data.data);
        tenantFetchProgressEnd();
    };

    const deleteTenantList = async (tenantIds: number[]): Promise<void> => {
        tenantDeleteProgressStart();
        const response = await TenantDelete.handle(tenantIds);

        if (isFailedResponse(response)) {
            error.value.hasError = true;
            error.value.message = response.data.data.message;
            tenantDeleteProgressEnd();
            return;
        }
        initStatus();
        tenantDeleteProgressEnd();
    };

    const hasTenant = (): boolean => !!tenantSystemList.value.length;

    return {
        error,
        tenantSystemList,
        deleteSelected,
        initDeleteSelected,
        fetchTenantList,
        deleteTenantList,
        hasTenant,
        initTenantSystemList,
    };
};
